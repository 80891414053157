<template>
  <div>
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="活动标题">
        <el-input
          size="mini"
          v-model="formInline.user"
          placeholder="请输入活动标题"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="mini" type="primary" @click="search">查询</el-button>
      </el-form-item>
    </el-form>
    <div class="label">总发放红包：{{ consumeAmount }}元</div>
    <el-tabs
      v-model="isShowOrderId"
      @tab-click="GlgetMaterialCategoryList"
      class="firstfencel"
    >
      <el-tab-pane
        :label="item.subjectName"
        :name="item.subjectId"
        :key="item.subjectId"
        v-for="item in list"
      ></el-tab-pane>
    </el-tabs>
    <el-table
      :data="tableData"
      :header-cell-style="{
        background: '#f8f8f9',
        color: '#515a6e',
        fontSize: '13px',
        textAlign: 'center'
      }"
      :cell-style="{ textAlign: 'center' }"
    >
      <el-table-column prop="topicNo" label="活动ID"> </el-table-column>
      <el-table-column prop="topicName" label="标题"> </el-table-column>
      <el-table-column prop="rewardAmount" label="奖励金额(元)">
      </el-table-column>
      <el-table-column label="统计">
        <template slot-scope="{ row }">
          <div class="zzz">
            <div>
              活动奖励数量：<span>{{ row.rewardNum }}</span
              >份
            </div>
            <div>
              观看视频人数：<span>{{ row.readPersonNum }}</span
              >人
            </div>
            <div>
              获取奖励人数：<span>{{ row.rewardPersonNum }}</span
              >人
            </div>
            <div>
              获取奖励金额：<span>{{ row.rewardPersonAmount }}</span
              >元
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template>
          <div style="color: #05b505">使用中</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300" align="left">
        <template slot-scope="{ row }">
          <div class="bbb">
            <el-button type="success" size="mini" @click="goRecord(row, 1)"
              >答题记录</el-button
            >
            <el-button type="success" size="mini" @click="goRecord(row, 2)"
              >红包记录</el-button
            >
            <el-button type="success" size="mini" @click="goRecord(row, 3)"
              >观看记录</el-button
            >
            <el-button type="primary" size="mini" @click="getUrl(row)"
              >活动链接</el-button
            >
            <el-button type="primary" size="mini" @click="edit(row)"
              >活动设置</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="fy">
      <div></div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[20, 30, 50]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog title="活动设置" :visible.sync="dialogVisible" width="500px">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="红包金额" prop="rewardAmount">
          <el-input v-model="ruleForm.rewardAmount"></el-input>
        </el-form-item>
        <el-form-item label="奖励数量" prop="rewardNum">
          <el-input v-model="ruleForm.rewardNum"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" size="small"
          >保存</el-button
        >
      </span>
    </el-dialog>

    <el-dialog title="活动地址" :visible.sync="dialogVisible1" width="800px">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="90px"
        class="demo-ruleForm"
      >
        <el-form-item label="活动地址">
          <el-input v-model="urls"></el-input>
        </el-form-item>
        <el-form-item label="活动二维码">
          <div id="qrCode" ref="qrCodeDiv"></div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false" size="small">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import {
  getStoreSubjectList,
  getStoreTopicPage,
  getStoreFundsStatisticsByCurrentStore,
  updateStoreTopicReward
} from '../../../api/user'
export default {
  data() {
    return {
      urls: '',
      code: '',
      dialogVisible1: false,
      ruleForm: {
        rewardAmount: '',
        rewardNum: ''
      },
      rules: {
        rewardAmount: [{ required: true, message: '请输入', trigger: 'blur' }],
        rewardNum: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      dialogVisible: false,
      consumeAmount: '',
      list: [],
      isShowOrderId: '',
      total: 0,
      size: 20,
      current: 1,
      tableData: [],
      formInline: {
        user: '',
        region: ''
      }
    }
  },
  watch: {
    dialogVisible(newVal) {
      if (!newVal) {
        this.ruleForm = {
          rewardAmount: '',
          rewardNum: ''
        }
      }
    }
  },
  created() {
    this.getStoreSubjectList()
    this.getStoreFundsStatisticsByCurrentStore()
  },
  methods: {
    goRecord(row, e) {
      if (e == 1) {
        this.$router.push(`/answer/answerRecord?id=${row.id}`)
      } else if (e == 2) {
        this.$router.push(`/answer/redEnvelope?id=${row.id}`)
      } else {
        this.$router.push(`/answer/viewingRecord?id=${row.id}`)
      }
    },
    getUrl(row) {
      this.urls = `https://user.huamaoxt.com/#/pages/video/video?topicId=${row.topicId}&storeId=${row.storeId}&id=${row.id}`
      this.dialogVisible1 = true
      this.$nextTick(() => {
        document.getElementById('qrCode').innerHTML = ''
        new QRCode(this.$refs.qrCodeDiv, {
          text: `https://user.huamaoxt.com/#/pages/video/video?topicId=${row.topicId}&storeId=${row.storeId}&id=${row.id}`,
          width: 200,
          height: 200,
          colorDark: '#333333', //二维码颜色
          colorLight: '#ffffff', //二维码背景色
          correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
        })
      })
    },
    edit(row) {
      this.ruleForm = {
        id: row.id,
        rewardAmount: row.rewardAmount,
        rewardNum: row.rewardNum
      }
      this.dialogVisible = true
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const data = await updateStoreTopicReward(this.ruleForm)
          if (data.code == 200) {
            this.dialogVisible = false
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
            this.getStoreTopicPage()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    search() {
      this.current = 1
      this.getStoreTopicPage()
    },
    async getStoreFundsStatisticsByCurrentStore() {
      const { data } = await getStoreFundsStatisticsByCurrentStore({})
      console.log(data)
      this.consumeAmount = data.consumeAmount || 0
    },
    GlgetMaterialCategoryList() {
      this.getStoreTopicPage()
    },
    async getStoreSubjectList() {
      const data = await getStoreSubjectList({})
      this.list = data.data
      if (this.list.length) {
        this.isShowOrderId = data.data[0].subjectId
        this.getStoreTopicPage()
      }
    },
    async getStoreTopicPage() {
      const data = await getStoreTopicPage({
        searchKey: this.formInline.user,
        pageSize: this.size,
        pageNum: this.current,
        subjectId: this.isShowOrderId
      })
      console.log(data)
      this.tableData = data.data.list
      this.total = data.data.total * 1
    },
    handleSizeChange(val) {
      this.size = val
      this.getStoreTopicPage()
    },
    handleCurrentChange(val) {
      this.current = val
      this.getStoreTopicPage()
    }
  }
}
</script>

<style lang="scss" scoped>
.bbb {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .el-button {
    margin-bottom: 10px;
    margin-left: 10px;
  }
}
.zzz {
  span {
    color: #ff6f00;
  }
}
.label {
  background-color: #f2f2f2;
  line-height: 50px;
  border-left: 5px solid #0088ff;
  padding-left: 20px;
  box-sizing: border-box;
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
  span {
    margin: 0 20px;
  }
}
.fy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
</style>
